import React from 'react';
import ContactForm from './ContactForm';

export default function Contact() {
  return (
    <div>
      <h1>Contact</h1>
      <ContactForm />
    </div>
  );
}
