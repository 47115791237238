import React from 'react'
import ReactVivus from 'react-vivus'
import svg from '../../images/Triquetra-circle-interlaced_animated.svg'

export const Triknot = () => {
  return (
      <div
        style={{
            overflow: 'hidden'
        }}
      >
        <ReactVivus
            id="triknot"
            option={{
            file: svg,
            animTimingFunction: 'EASE',
            type: 'delayed',
            duration: 300
            }}
            callback={console.log}
        />
    </div>
  )
}
