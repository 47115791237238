import evidenced from '../../images/evidencednutrition.png';
import lig from '../../images/ligsolutions.png';
import litehouse from '../../images/litehouse.png';
import occrra from '../../images/occrra.png';

const images = [
  {
    id: 1,
    src: occrra,
    alt: 'occrra',
    title: 'Ohio Child Care Resource and Referral Association',
    overview: 'The client is a non-profit organization that supports nearly 40,000 different child care professionals in the state of Ohio, as well as neighboring states. They receive funding from the Federal and State Government to distribute to various child care organizations that manage child care professionals, as well as directly to the professionals themselves. In addition, they also host registration for training and various other services for these professionals to sign up for to maintain credentials and licensure. At the time, the organization was manually distributing out funds via hand written checks delivered through the mail. Additionally, in order to receive funds to disperse, the organization had to also manually request funds from their previous payment processor, locking up approximately $1.3M in funds that was needed to be regularly accessed for the organizations and professionals they supported.',
    solution: "A new payment processing system through Stripe was directly integrated into their website in order to streamline the customer experience. Additionally, to solve the issue of distribution of funds to tens of thousands of individuals, a payment platform was developed for the client to automate payout of funds to organizations and individuals who registered training events and services through their website. Utilizing Stripe's Connect servies, any registration through their platform would be automatically processed and scheduled for payouts to the respective individual's or organization's connected Stripe or Bank account.",
    outcomes: "Utilizing Stripe as a solution, the issue of both manually requesting and distributing funds drastically reduced labor hours for the client. Approximately 300 additional hours of labor per month was dropped by automating the process of manually distributing funds and payouts alone. An additional 200 hours per month in revenue reconciliation and accounting processes were dropped with the Stripe integration by automating the reporting process in the accounting department to reconcile and match funds to the appropriate parties. Requests to the previous payment processor, which accounted for a variable amount of time in which funds would be delivered to the client for distribution, was reduced by 100% as the client now had direct access to their own funds. Finally, costs and fees from their previous payment processor, which charged them both for processing the payment and accessing their funds, as well as costs for physically mailing the funds to individuals and organizations, were reduced by an estimated $100,000 annually.",
    link: 'https://occrra.org/'
},
  {
    id: 2,
    src: evidenced,
    alt: 'evidencednutrition',
    title: 'Evidenced Nutrition',
    overview:
      'The client had previously been utilizing a CMS platform to manage their consultation busienss. However, as a clinician, they did not have the time to manage a website on their own. In fact, in their attempt to upkeep their online presence, they had multiple websites built on CMS platforms websites that needed to be streamlined. Finally, they wanted to streamline their payment processing, as they were manually charging individuals through their business bank account.',
    solution:
      'Utilizing a custom solution, a completely new website was built using a modern Full Stack framework with React.JS for the client side, and Express.JS for the backend. Payment processing is handled through Stripe, and directly integrated into the website to ensure all relevant information about the client is collected, which plugin payment processors do not always collect to ensure secure payments through Stripes AI and ML security processes. In addition, custom solutions for the client that they wanted to carry over from their CMS, such as a blog and contact forms, were built for the client. The website is hosted on platforms that, for the size of the business, do not cost additional fees.',
    outcomes:
      "The client's website is modernized and lightweight, with more content for improved branding. Performance metrix were improved, offering a 75% increase in speed and loading compared to the previous two website iterations, improving user experience. Direct integration of Stripe into the website, vs plugins, offers more secure payments and reduced processing feees. Finally, utilizing alternative hosting services, monthly hosting fees for the client have been reduced to $0 per month, as compared to pay as you go models for any data used, or monthly fees for platform use through traditional CMS hosting platforms.",
    link: 'https://www.evidencednutrition.com/'
    },
  {
    id: 3,
    src: lig,
    alt: 'ligsolutions',
    title: 'LIG Solutions',
    overview:
      'The client needed an updated website on the client side, but they wanted the ability to update or add elements to the site on their own.',
    solution:
      "A custom Wordpress theme was developed as a base for the client's site so that any new elements or content added to the front-end would match the new branding and style of the site.",
    outcomes: "Speed and performance of the client's website was improved by 50% by reducing site weight through a custom Wordpress theme and reduced plugin dependency. With a custom theme solution, the client is able to integrate or add any additional and elements in the future to match their updated branding.",
    link: "https://ligsolutions.com/"
},
  {
    id: 4,
    src: litehouse,
    alt: 'litehouse_pools',
    title: 'Litehouse Pools and Spas',
    overview: "The client needed an updated website, as well as eCommerce platform through Woocommerce that would streamline the services and products from their multiple brick and mortar locations.",
    solution: "A custom Wordpress theme was developed as a base for the client's site so that any new elements or content added to the front-end would match the new branding and style of the site. The client's products and services from their multiple locations were collected and migrated into a new MySQL database hosted on the cloud from their physical server, and connected directly into their Woocommerce platform to auto populate products and services online.",
    outcomes: "Speed and performance of the website was improved by 60% by reducing site weight and bloat by removing unecessary plugins and elements from their CMS platform. In addition, business operations were modernized by migrating products and services, as well as business information and accounts, from physical servers at individual locations, to a single database hosted on the cloud.",
    link: 'https://litehouse.com/'
},
];

export default images;
