import React from 'react';
import images from '../Gallery/images';

export default function Portfolio() {
  return (
    <div
      style={{
        marginTop: '10%',
      }}
    >
      {images.map((e) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '60%',
              margin: 'auto',
              marginBottom: '5%',
            }}
          >
              <a href={e.link}>
            <img
              src={e.src}
              alt={e.alt}
              style={{ width: '100%', margin: 'auto' }}
            />
            </a>
            <h2>{e.title}</h2>
            <p style={{
                textAlign: 'left'
            }}><strong>Overview: </strong> {e.overview}</p>
            <p style={{
                textAlign: 'left'
            }}><strong>Solution: </strong> {e.solution}</p>
            <p style={{
                textAlign: 'left'
            }}><strong>Outcome: </strong> {e.outcomes}</p>
          </div>
        );
      })}
    </div>
  );
}
