import React from 'react';
import { Formik, Field, Form } from 'formik';
import { sendEmail } from '../../utils/sendEmail';

import Swal from 'sweetalert2';

export default function ContactForm() {
  const successAlert = () => {
    Swal.fire({
      title: 'Thank you!',
      text: 'Your contact form has been submitted! We will contact you within the next 24-48 hours.',
      icon: 'success',
      timer: 5000,
      timerProgressBar: 'true',
      allowEscapeKey: 'true',
      confirmButtonText: 'Continue',
    }).then(() => {
      localStorage.clear();
      window.location.href = '/';
      // console.log('Form Values: ', values);
    });
  };

  return (
    <div className="form_container">
      {' '}
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          business: '',
          website: '',
          radio: '',
          checked: [],
          line1: '',
          line2: '',
          city: '',
          state: '',
          postal_code: '',
        }}
        onSubmit={async (values) => {
          sendEmail(values);
          successAlert();
        }}
      >
        {({ values }) => (
          <Form className="form">
            <div className="form_wrapper">
              <h3
                style={{
                  textAlign: 'left',
                }}
              >
                Contact Information
              </h3>
              <div className="customer_name">
                <Field
                  className="field name"
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                />
                <Field
                  className="field name"
                  id="lastName"
                  name="lastName"
                  placeholder="Last Name"
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Field
                  className="field"
                  id="business"
                  name="business"
                  placeholder="Business Name"
                />
                <Field
                  className="field"
                  id="email"
                  name="email"
                  placeholder="Email"
                  type="email"
                />
                <Field
                  className="field"
                  id="phone"
                  name="phone"
                  placeholder="Phone Number"
                  type="phone"
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <label
                    className="label"
                    style={{ textAlign: 'left' }}
                  >
                    Is this a personal phone or work phone?
                  </label>
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <label className="label">
                      <Field
                        type="radio"
                        name="radio"
                        value="personal"
                      />
                      Personal
                    </label>
                    <label className="label">
                      <Field type="radio" name="radio" value="work" />
                      Work
                    </label>
                  </div>
                </div>
              </div>
              <h3
                style={{
                  textAlign: 'left',
                }}
              >
                Address
              </h3>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Field
                  className="field"
                  id="line1"
                  name="line1"
                  placeholder="Street Address"
                />
                <Field
                  className="field"
                  id="line2"
                  name="line2"
                  placeholder="Apt/Suite Number"
                />
                <Field
                  className="field"
                  id="city"
                  name="city"
                  placeholder="City"
                />
                <Field
                  className="field"
                  id="state"
                  name="state"
                  placeholder="State"
                />
                <Field
                  className="field"
                  id="postal_code"
                  name="postal_code"
                  placeholder="Zip Code"
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Field
                  className="field"
                  id="website"
                  name="website"
                  placeholder="Website Address (leave empty if you don't have a website)"
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  marginTop: '10px',
                  flexDirection: 'column',
                }}
              >
                <label className="label">
                  Please select all of the services you are interested
                  in:
                </label>
                <div className="checkboxes">
                  <div
                    className="checkboxes_container"
                  >
                    <label
                      className="label"
                    >
                      <Field
                        type="checkbox"
                        name="checked"
                        value="Web Design"
                      />
                      Web Design
                    </label>
                    <label className="label">
                      <Field
                        type="checkbox"
                        name="checked"
                        value="eCommerce Integration"
                      />
                      eCommerce Integration
                    </label>
                    <label className="label">
                      <Field
                        type="checkbox"
                        name="checked"
                        value="SEO"
                      />
                      SEO
                    </label>
                    <label className="label">
                      <Field
                        type="checkbox"
                        name="checked"
                        value="Email Marketing"
                      />
                      Email Marketing and Design
                    </label>
                  </div>
                  <div className="checkboxes_container">
                    <label className="label">
                      <Field
                        type="checkbox"
                        name="checked"
                        value="Targeted Ads"
                      />
                      Targeted Advertising
                    </label>
                    <label className="label">
                      <Field
                        type="checkbox"
                        name="checked"
                        value="Platform"
                      />
                      Platform or App Development
                    </label>
                    <label className="label">
                      <Field
                        type="checkbox"
                        name="checked"
                        value="Marketplace"
                      />
                      Marketplace
                    </label>
                    <label className="label">
                      <Field
                        type="checkbox"
                        name="checked"
                        value="other"
                      />
                      Other
                    </label>
                  </div>
                </div>
              </div>
              <div className="message_container">
                <Field
                  as="textarea"
                  className="field messagefield"
                  name="message"
                  placeholder="Please enter message here."
                  type="textarea"
                />
                <button type="submit" className="formButton">
                  Submit
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
