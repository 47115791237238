import emailjs, { init } from '@emailjs/browser';
init('user_uscJE2OKi8jiqjr9ITiY4');

export const sendEmail = (values) => {
  console.log('Values: ', values);

  const {
    firstName,
    lastName,
    email,
    business,
    website,
    phone,
    radio,
    checked,
    message,
    line1,
    line2,
    city,
    state,
    postal_code
  } = values;

  console.log('first name: ', firstName);
  console.log('message: ', message);

  const from_name = firstName + ' ' + lastName;
  const reply_to = email;

  emailjs
    .send('default_service', 'template_kx7p64n', {
      from_name,
      email,
      firstName,
      lastName,
      message,
      business,
      website,
      phone,
      radio,
      checked,
      line1,
      line2,
      city,
      state,
      postal_code
    })
    .then((res) => {
      console.log('Email sent successfully.');
    })
    .catch((err) => console.log('Error sending email: ', err));
};
