import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Gallery = ({ images }) => {
  const settings = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <>
      <div className="tag">
        <h1 style={{ fontFamily: "'Raleway', sans-serif" }}>
          Portfolio
        </h1>
      </div>
      <div className="imgslider">
        <Slider {...settings}>
          {images.map((item) => (
            <div key={item.id}>
              <a
                href='/portfolio'
                style={{
                  textDecoration: 'none',
                  color: 'black'
                }}
              >
                <img src={item.src} alt={item.alt} />
                <h2 style={{ fontFamily: "'Raleway', sans-serif" }}>
                  {item.title}
                </h2>
              </a>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default Gallery;
