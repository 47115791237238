import React from 'react';
import { Triknot } from '../Animations/Triknot';
import Gallery from '../Gallery';
import images from '../Gallery/images';

import stripeLogo from '../../images/stripe.png';

export default function Landing() {
  return (
    <div>
      <div className="landing">
        <h1 className="fade-in-text2">Ley Line Development</h1>
        <Triknot />
        <h2 className="fade-in-text">
          The Bridge Between Businesses and Customers
        </h2>
      </div>
      <div className="about">
        <div className="content">
          <h2 className="header2">About Ley Line Development</h2>
          <p>
            Ley Line Development was started with a simple goal in
            mind: bringing Big Tech to small businesses. Whether you
            are just starting a business, or you want to update your
            online presence, Ley Line Development can offer a suite of
            solutions for your business needs, at the fraction of a
            cost.
            <br />
            <br />
            With expertise in modern Web languages and Web API, Ley
            Line Development can create a lightweight, but powerful
            Web Site or Web based application, powered by the same
            tools and platforms utilized by companies like Shopify,
            Lyft, and Amazon.
          </p>
        </div>
      </div>
      <div className="marketing">
        <div className="quarter">
          <h3>Modernized Marketing</h3>
          <p>
            Utilizing modern development practices and technologies,
            the need for extra applications or plugins are obsolete.
            No need for extra plugins or apps, when your SEO and
            Marketing is built into your website.
          </p>
        </div>
        <div className="quarter">
          <h3>Powered by AI</h3>
          <p>
            Big Data isn't just for corporations or Unicorns anymore,
            it can be utilized by everyone. Powered by some of the
            most powerful machine learning and A.I. in the market,
            your business will start where had to build up to over
            decades.
          </p>
        </div>
        <div className="quarter">
          <h3>Affordable Solutions</h3>
          <p>
            Direct development doesn't have to be a make or break for
            your budget. With our partners and tools, we are able to
            offer variety of payment options and plans to work within
            your business's budget.
          </p>
        </div>
      </div>
      <div className="gallery">
        <Gallery images={images} />
      </div>
      <div className="stripe">
        <img
          className="stripelogo"
          alt="stripelogo"
          src={stripeLogo}
        />
        <div className="content">
          <h2>Powered by Stripe</h2>
          <p>
            As a Partner and Certified Stripe Developer, Ley Line
            Development is an expert in Stripe's suite of products and
            services. If you just need a simple invoicing and billing
            system, a complete eCommerce site, or your own Platform
            that needs to manage payouts to employees like Lyft or
            Doordash, we can build it directly with Stripe, no
            additional apps or plugins needed.
          </p>
        </div>
      </div>
      <div className="business_solutions">
        <h2>Business Solutions</h2>
        <div
          className="content"
          style={{
            display: 'flex',
            margin: 'auto',
            minHeight: '300px',
            width: '75%',
          }}
        >
          <div className="quarter grid">
            <h3>Payments</h3>
            <p>
              Accept payments in person and online, and even accept
              Buy-Now-Pay-Later options.
            </p>
          </div>
          <div className="quarter grid">
            <h3>Billing</h3>
            <p>
              Create invoices and billing, including subscriptions and
              reocurring payments.
            </p>
          </div>
          <div className="quarter grid">
            <h3>Automated Reports</h3>
            <p>
              Improve accounting efficiency with Stripe's automated
              reporting.
            </p>
          </div>
        </div>
        <div className="content spacer-top">
          <div className="quarter grid">
            <h3>Platform Billing</h3>
            <p>
              Build a payment and billing system for your PaaS of
              SaaS, or other platform.
            </p>
          </div>
          <div className="quarter grid">
            <h3>Chargeback Protection</h3>
            <p>
              Collect all the information to validate each and every
              purchase to prevent friendly fraud.
            </p>
          </div>
          <div className="quarter grid">
            <h3>Fraud Prevention</h3>
            <p>
              Use AI to monitor and protect you and your customers
              from fraudulent transactions.
            </p>
          </div>
        </div>
      </div>
      <div className="cta">
        <h2>Ready to get started?</h2>
        <button
          className="cta-button"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = '/contact';
          }}
        >
          Submit your Project Details
        </button>
      </div>
    </div>
  );
}
