import React from 'react';

export default function Services() {
  return (
    <div
      style={{
        marginTop: '5%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <h1>Services</h1>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div className="services">
          <div className="service">
            <h2>SEO and Performance Audit</h2>
            <p>
              In order for your website to appear higher and higher on
              Google, you need two things: relevancy and speed. It's
              not just enough to plug in the right keywords, or
              description for your website anymore to be relevant.
              <br />
              <br />
              Performance of your website is even more important than
              the content of your site. If your site is slow, has its
              content in the wrong format, or too much bloat from
              excess code and unused plugins, then you'll end up lower
              on Google and other page rankings.
              <br />
              <br />
              Our SEO and Performance Audit service will scan your
              entire website, looking at both its strengths and
              weaknesses, and offer a comprhensive guide to assist you
              in moving your website up the rankings so your business
              has more visibility.
            </p>
          </div>
          <div className="service">
            <h2>Stripe Payment Integration</h2>
            <p>
              If you already have an existing business, whether you're online or 
              in person, but you're not satsified with your current payment processor,
              we can help.
              <br />
              <br />
              By directly integrating Stripe into your brick and mortar or website checkout
              flow, you can increase sales quickly and effectively by either redirecting your 
              customers to a secure Stripe Checkout form, or by manually handlng the chekcout 
              at your Stripe POS terminal in person.
              <br />
              <br />
              In either case, you can be assured that your sales, whether online or in person,
              will be handled quickly and securely, and will be set up the fastest way possible
              so your business operations aren't interrupted.
            </p>
          </div>
          <div className="service">
            <h2>eCommerce Platform</h2>
            <p>
              If you already have a website, but you need more than just a payment processor online, 
              then you might need to upgrade to your own eCommerce platform. 
              <br />
              <br />
              With your own eCommerce platform, you will be able to manage everything from 
              payments, billing and subscriptions to increase your revenue flow. In addition, you 
              can also manage your own reports, whether for revenue reconciliation, accounting, 
              or tax season. You can even utilize Stripe's Radar and Identity solutions to increase 
              security for your business and prevent fraudulent transactions, Friendly Fraud, and
              chargebacks.
              <br />
              <br />
              With your own custom eCommerce Platform, you can take control of your business's
              financial and accounting operations in one location.
            </p>
          </div>
          <div className="service">
            <h2>Website Development</h2>
            <p>
              If you're looking to launch your own website, or upgrade your site to a more 
              modern and powerful platform, then this is the service for you.
              <br />
              <br />
              Whether you're looking for a website to promote your brick and mortar business, 
              share your social media posts, or to manage and share your own content, we can build 
              it.
              <br />
              <br />
              The need to rely on monthly services for content management platforms is over. You can
              have all the same features as a Wordpress or Squarespace website, without the hassle of 
              setting up blogs or plugins yourself.
            </p>
          </div>
          <div className="service">
            <h2>SaaS and PaaS</h2>
            <p>
              Whether you have your own software as a service solution and you need a unified payment 
              solution to streamline customer experience, or you're looking to build your own software,
              we can help.
            </p>
            <p>
              Doesn't matter if you have the new cloud based storage solution, or the next project management
              application, you can improve customer experience and accounting services by integrating 
              your own unified billing service with Stripe. 
            </p>
          </div>
          <div className="service">
            <h2>Custom Web Application Solution</h2>
            <p>
              Do you have an idea you think would be a great app? Maybe the next big streaming 
              platform? Or maybe you want to take disrupt the gig-economy with the next ride 
              share service? Or maybe you already have one but you still need to get the 
              payment and accounting system up and running?
            </p>
            <p>
              Whether starting from scratch with your idea, or putting on the finishing 
              touches to power your platform, Ley Line Development can help. With our 
              expertise in modern web based applications and API's, your custom solution 
              is ready and waiting. 
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
