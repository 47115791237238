import React, { useState, useEffect } from 'react';
import { debounce } from '../debounce';
import svg from '../images/Triquetra-circle-interlaced_animated.svg';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  // Navigate,
  // Outlet
} from 'react-router-dom';
import Landing from './Landing';
import Contact from './Contact';
// import Business from './Business';
import Portfolio from './Portfolio';
import Services from './Services';

export default function Nav() {
  const navbarStyle = {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '80%',
    left: '10%',
    top: '3%',
    transition: 'top 0.6s',
  };

  const mediumStyle = {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '60%',
    left: '5%',
    top: '3%',
    transition: 'top 0.6s',
  };

  const smallStyle = {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    left: '10%',
    top: '3%',
    transition: 'top 0.6s',
  };

  const [prevScrollPos, setPrevScrollPos] = useState(0);

  const [visible, setVisible] = useState(true);

  const handleScroll = debounce(() => {
    const origin = window.origin + '/';

    if (origin !== window.location.href) {
      setVisible(false);
    } else {
      const currentScrollPos = window.pageYOffset;

      setVisible(
        (prevScrollPos > currentScrollPos &&
          prevScrollPos - currentScrollPos > 70) ||
          currentScrollPos < 10
      );

      setPrevScrollPos(currentScrollPos);
    }
  }, 100);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  return (
    <div className="wrapper">
      <BrowserRouter>
        <div>
          <nav
            className="navbar"
            style={
              window.innerWidth > 1007
                ? {
                    ...navbarStyle,
                    zIndex: '9999',
                    top: visible ? '-60px' : '20px',
                  }
                : window.innerWidth <= 1007 || window.innerWidth >= 641
                ? {
                    ...mediumStyle,
                    zIndex: '9999',
                    top: visible ? '-60px' : '20px',
                  }
                : window.innerHeight < 640
                ? {
                    ...smallStyle,
                    zIndex: '9999',
                    top: visible ? '-60px' : '20px',
                  }
                : { display: 'none' }
            }
          >
            <div>
              <Link
                to="/"
                style={{ textDecoration: 'none', color: 'black' }}
              >
                <img
                  src={svg}
                  alt="logo"
                  style={{
                    width: '50px',
                  }}
                />
              </Link>
            </div>
            <div
            className='link_container'
            >
              <Link className="stroke link" to="/services">
                Services
              </Link>
              <Link className="stroke link" to="/portfolio">
                Portfolio
              </Link>
              {/* <Link className="stroke link" to="/pricing">
                Pricing
              </Link> */}
              <Link className="stroke link" to="/contact">
                Contact
              </Link>
            </div>
          </nav>
        </div>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/contact" element={<Contact />} />
          {/* <Route path="/pricing" element={<Business />} /> */}
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/services" element={<Services />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
